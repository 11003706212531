<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div v-if="item" class="footer-one-index">
          <div style="width: 100%">
            <div class="footer-one-index-title">博蓝达，旨在更好的为客户提供服务</div>
            <div class="footer-one-index-buttom"><el-button style="color: #3287FF">了解详情</el-button></div>
          </div>
        </div>
        <div v-else class="footer-one-info">
          <div style="width: 100%">
            <div class="footer-one-info-title">全方位实时掌控酒店，助您实现业绩增长</div>
            <div class="footer-one-info-note" style="display: none">请扫描右侧二维码 <i style="font-size: 12px" class="el-icon-d-arrow-right"></i></div>
          </div>
<!--          <div class="footer-one-info-img"><img width="100%" height="100%" src="https://cdn.blanda.com.cn/assets/img/pub/ewm.png" alt=""></div>-->
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="footer-two content">
          <div>
            <el-row>
              <el-col :span="6">
                <div class="footer-two-icon">
                  <img src="https://cdn.blanda.com.cn/assets/img/pub/aqwd.png" alt="">
                  <div>安全稳定</div>
                </div>
              </el-col>
              <el-col :span="6" :offset="1">
                <div class="footer-two-icon">
                  <img src="https://cdn.blanda.com.cn/assets/img/pub/gnqm.png" alt="">
                  <div>功能全面</div>
                </div>
              </el-col>
              <el-col :span="6"  :offset="1">
                <div class="footer-two-icon">
                  <img src="https://cdn.blanda.com.cn/assets/img/pub/jkbj.png" alt="">
                  <div>接口便捷</div>
                </div>
              </el-col>
              <el-col :span="3" :offset="1">
                <div class="footer-two-icon">
                  <img src="https://cdn.blanda.com.cn/assets/img/pub/ksbs.png" alt="">
                  <div>快速部署</div>
                </div>
              </el-col>
            </el-row>

          </div>
          <el-divider class="divider"></el-divider>
          <div class="footer-content">
            <el-row>
              <el-col :span="6"  style="height: 400px">
                <div class="pad-5"><img src="https://cdn.blanda.com.cn/assets/img/pub/logo-h.png" width="120" height="50" alt=""></div>
                <div class="footer-content-title pad-5 mart-20">联系我们</div>
                <div class="footer-content-title pad-5">400-900-1678 <span>(服务专线)</span></div>
                <div class="pad-5">邮箱:Support@Blanda.com.cn</div>
                <div class="pad-5">总部地址:重庆市沙坪坝区西荣路18号</div>
                <div class="footer-content-ewm pad-5">
                  <div>
                    <div><img src="https://cdn.blanda.com.cn/assets/img/pub/ewm.png" alt=""></div>
                    <div>微信公众号</div>
                  </div>
                  <div class="mar-30">
                    <div><img src="https://cdn.blanda.com.cn/assets/img/pub/ewm.png" alt=""></div>
                    <div>商城小程序</div>
                  </div>
                </div>
                <div class="footer-content-share-content pad-5">
<!--                  <div class="footer-content-share-content-img"><img width="100%" src="https://cdn.blanda.com.cn/assets/img/pub/wx.png" alt=""></div>
                  <div class="footer-content-share-content-img"><img width="100%" src="https://cdn.blanda.com.cn/assets/img/pub/wx.png" alt=""></div>
                  <div class="footer-content-share-content-img"><img width="100%" src="https://cdn.blanda.com.cn/assets/img/pub/wx.png" alt=""></div>
                  <div class="footer-content-share-content-img"><img width="100%" src="https://cdn.blanda.com.cn/assets/img/pub/wx.png" alt=""></div>
                  <div class="footer-content-share-content-img"><img width="100%" src="https://cdn.blanda.com.cn/assets/img/pub/wx.png" alt=""></div>
                  <div class="footer-content-share-content-img"><img width="100%" src="https://cdn.blanda.com.cn/assets/img/pub/wx.png" alt=""></div>-->
                </div>
              </el-col>
              <el-col :span="3" style="height: 400px;display: flex;align-items: center;justify-items: center">
                <div style="width: 1px;height: 350px;background:#B4B9C4;opacity: 0.29;margin: 0 auto;"></div>
              </el-col>
              <el-col :span="15" >
                <el-row>
                  <el-col :span="10"   >
                    <div class="footer-content-title pad-5">产品</div>
                    <div class="pad-7" @click="on_link('Pms')">PMS前台客房管理系统平台</div>
                    <div class="pad-7" @click="on_link('Pos')">POS餐饮收银零售系统平台</div>
                    <div class="pad-7" @click="on_link('Sc')">S&C宴会销售系统平台</div>
                    <div class="pad-7" @click="on_link('Eng')">ENG工程报修系统平台</div>
                    <div class="pad-7" @click="on_link('Ac')">AC财务管理系统平台</div>
                    <div class="pad-7" @click="on_link('Mms')">MMS会员储值卡系统平台</div>
                    <div class="pad-7" @click="on_link('Picc')">PICC采购成本系统平台</div>
                    <div class="pad-7" @click="on_link('Hrm')">HRM人事薪资考勤系统平台</div>
                    <div class="pad-7" @click="on_link('Eam')">EAM固定资产系统平台</div>
                    <div class="pad-7" @click="on_link('Zz')">BMS早餐核销系统平台</div>
                    <div class="pad-7" @click="on_link('Zz')">OPI证件扫描系统平台</div>
                  </el-col>
                  <el-col :span="10" >
                    <div style="margin-left: 20px">
                      <div class="footer-content-title pad-5"><a href="./">解决方案</a></div>
                      <div class="pad-7" @click="on_link('Zh')">综合型酒店管理系统</div>
                      <div class="pad-7" @click="on_link('Restaurant')">餐饮零售营销平台</div>
                      <div class="pad-7" @click="on_link('Ams')">商务公寓快捷连锁</div>
                      <div class="pad-7" @click="on_link('Group')">集团化业务中台</div>
                      <div class="pad-7" @click="on_link('Jq')">智慧景区票务一卡通</div>
                      <div class="pad-7" @click="on_link('Zz')">国际联号增值产品</div>
                      <div class="kg-20"></div>
                      <div class="footer-content-title pad-5" @click="on_link('Hyal')">行业案例</div>
                      <div class="pad-7" @click="on_link('Hyal')">合作集团</div>
                      <div class="pad-7" @click="on_link('Hyal')">成功案例</div>
                    </div>

                  </el-col>
                  <el-col :span="4">
                    <div style="margin-left: 20px">
                      <div class="footer-content-title pad-5" @click="on_link('Hbstq')">伙伴生态圈</div>
                      <div class="pad-7" @click="on_link('Hbstq')">成为合作伙伴</div>
                      <div class="pad-7" @click="on_link('Hbstq')">查询伙伴</div>
                      <div class="kg-30"></div>
                      <div class="footer-content-title pad-5" @click="on_link('Shfw')">售后服务</div>
                      <div class="pad-7" @click="on_link('Shfw')">项目管理</div>
                      <div class="pad-7" @click="on_link('Shfw')">售后服务</div>
                      <div class="kg-30"></div>
                      <div class="footer-content-title pad-5" @click="on_link('Gy')">关于Blanda</div>
                      <div class="pad-7" @click="on_link('Gy')">公司简介</div>
                      <div class="pad-7" @click="on_link('Gy')">联系我们</div>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="copyright">重庆统软科技有限公司 @2015-2024    <a style="color: #a1a0a0;" href="https://beian.miit.gov.cn/" target="_blank">渝ICP备15002512号-6</a></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data () {
    return {
      year: new Date().getFullYear(),
      item:0,
      footer_bg:'https://cdn.blanda.com.cn/assets/img/pub/footer-bg.png',
    }
  },
  methods: {
    on_link(str){
      /*const href = this.$router.resolve({
        name: str,
      }).href;
      //打开新的页面 到 href 这个页面
      window.open(href, "_self");*/
      this.$router.push({
        name:str,
      }, () => {})
    },
  }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color:black;
}
.footer-one-index{
  width: 100%;
  height: 180px;
  color: #ffffff;
  background-image: url("https://cdn.blanda.com.cn/assets/img/pub/footer-bg.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}
.footer-one-index-title{
  font-size: 34px;
  font-weight: normal;
  text-align: left;
}
.footer-one-index-buttom{
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  margin-top: 20px;
}
.footer-one-info{
  width: 100%;
  height: 249px;
  color: #ffffff;
  background-image: url("https://cdn.blanda.com.cn/assets/img/pub/footer-bg.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
}
.footer-one-info-title{
  font-size: 34px;
  font-weight: normal;
  text-align: center;
}
.footer-one-info-note{
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}
.footer-two-icon{
  display: flex;
  align-items: center;
  height: 80px;
  padding: 24px 0 0 0 ;
}
.footer-two-icon> img{
  width: 30px;
  height: 34px;
}
.footer-two-icon> div{
  margin-left: 35px;
  font-size: 18px;
  font-weight: normal;
  color: #0F0F0F;
}
.footer-content{
  font-size: 16px;
  font-weight: 400;
  color: #374663;
  padding: 40px 0;
}
.footer-content-title{
  font-size: 17px;
  font-weight: 600;
  color: #0F0F0F;
}
.footer-content-ewm{
  display: flex;
}
.footer-content-ewm img{
  width: 80px;
  height: 82px;
}
.footer-content-ewm div{
  text-align: center;
}
.footer-content-share-content{
  display: flex;
}
.footer-content-share-content-img{
  width: 40px;
  height: 40px;
  margin-right: 16px;
}


@media screen and (min-width: 1600px){
  .footer-one-info-img{
    position: absolute;
    right: 240px;
    width: 88px;
    height: 88px;
  }
  .footer-one-index{
    padding: 0 240px;
  }

}
@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-one-info-img{
    position: absolute;
    right: 140px;
    width: 88px;
    height: 88px;
  }
  .footer-one-index{
    padding: 0 140px;
  }

}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .footer-one-info-img{
    position: absolute;
    right: 40px;
    width: 88px;
    height: 88px;
  }
  .footer-one-index{
    padding: 0 40px;
  }

}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .footer-one-info-img{
    position: absolute;
    right: 40px;
    width: 88px;
    height: 88px;
  }
  .footer-one-index{
    padding: 0 40px;
  }

}
.pad-5{
  padding: 5px 0px;
  cursor: pointer;
}
.pad-7{
  padding: 9px 0px;
  cursor: pointer;
}
.mart-20{
  margin-top: 20px;
}
.mar-14{
  margin-left: 14px;
}
.mar-30{
  margin-left: 30px;
}
.kg-50{
  height: 50px;
}
.kg-30{
  height: 30px;
}
.kg-20{
  height: 20px;
}
.copyright {
  color: #a1a0a0;
  font-size: 14px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
}
.margin-left {
  margin-left: 10px;
}
</style>
